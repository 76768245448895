import React from "react"
import Layout from "../components/layout"
import Container from "../components/container"
import SEO from "../components/seo"
import styled from "@emotion/styled"

const Styles = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  margin: 0 20px;
  padding-bottom: 20px;

  h1 {
    font-family: "Barlow Condensed SemiBold";
    font-size: 28pt;
  }
  h2 {
    font-family: "Barlow Condensed SemiBold";
    font-size: 18pt;
    margin: 15px 0;
  }
  h4 {
    font-family: "Barlow Condensed SemiBold";
    font-size: 28pt;
    color: red;
    margin: 15px 0;
    text-align: center;
  }
  h3 {
    font-family: "Barlow Condensed SemiBold";
    font-size: 14pt;
  }
  p,
  li {
    font-family: "Roboto";
    font-size: 11pt;
  }
  a {
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
  }
  span {
    font-weight: bold;
    text-align: center;
    display: block;
    font-family: "Roboto";
    font-size: 12pt;
  }

  p {
    margin-bottom: 10px;
  }

  ul {
    list-style: disc;
    padding-left: 15px;
    margin-left: 15px;
  }
`

export default class Page extends React.Component {
  constructor() {
    super()
    this.state = {}
  }

  render() {
    return (
      <Layout margin={"50px"}>
        <SEO title="Ticket License" />
        <Styles>
          <Container>
            <span>
              By your acceptance and/or use of any electronic ticket on the
              Ticket Hoss app (“Ticket”) for an Event (“Event”) at the
              participating racetrack, recreational facilities, and other
              participating venues (“Venue”), the Ticket holder (“Holder”), on
              behalf of himself/herself and any minor(s) accompanying Holder
              (“Minor”), Holder and Minor together are referred together herein
              as (“Releasors”), agrees to the terms available at
              <a href="/terms"> www.tickethoss.com/terms </a> (“Agreement”) also
              available by requesting via email at{" "}
              <a href="mailto:Info@PitPay.com"> Info@PitPay.com</a>. Certain
              terms of the Agreement are summarized below. In the event of a
              conflict, the Agreement controls
            </span>
            <h4>WARNING: RISKS!</h4>
            <h2>WARNING:</h2>
            <p>
              Releasors acknowledge and assume all risks and dangers associated
              with the Event or any other promotions or activities before,
              during or after the Event (“Event Activities”), acknowledges that
              attendance is voluntary, and further agrees that Ticket Hoss,
              Operator, Venue, promoters, participants, racing associations,
              sanctioning organizations, or any subdivision thereof, track
              owners, officials, competition vehicle owners, drivers, pit crews,
              rescue personnel, sponsors, advertisers, owners and lessees of the
              Venue, premises and event inspectors, and all related entities and
              affiliates, together with their respective owners, officers,
              directors, employees, agents, licensees, sponsors, and vendors
              (collectively, the “Released Parties”), will not be responsible
              for any personal injury (including death), illness, property
              damage, or other loss suffered in connection with the Event
              Activities, including the negligence of any of the Released
              Parties or any other incidents caused by the Event Activities or
              crowds of people. By attending the Event Activities, Releasors are
              deemed to have given a full release of liability to the Released
              Parties to the fullest extent permitted by law from any and all
              claims which Releasors have or may have for causes of action
              arising out of attendance at, observation of and/or participation
              in the Event Activities.
            </p>
            <h2>ILLNESS:</h2>
            <p>
              Releasors acknowledge and understand that the Released Parties,
              and in particular the Venue and the Event Activities organizers,
              abide by all applicable federal, state and local guidelines and
              orders relating to the Venue and/or Event Activities, including
              without limitation preventative measures put in place to reduce
              the spread of COVID-19; however, the Released Parties cannot and
              will not guarantee that any persons present or in attendance at
              the Venue or Event Activities will not become exposed to or
              infected with COVID-19 or any other communicable disease or
              illness, some of which may carry a risk of suffering severe
              personal injury and/or death, despite reasonable efforts to
              mitigate such dangers. In addition to the Ticket Release and
              Waiver of Liability, Assumption of Risk and Indemnity Agreement
              stated above, Releasors hereby release, waive, discharge and
              covenant not to sue the Released Parties from all liability to the
              Releasors, including Releasors’ personal representatives, assigns,
              heirs, and next of kin, for any and all loss or damage, and any
              claim or demands therefor, on account of Releasors contraction of
              any communicable illness or disease, including COVID-19, arising
              out of or in any way related to the Venue, the Event Activities
              and/or Releasors’ attendance at the Event Activities, whether
              caused by the negligence of the Released Parties or otherwise.
            </p>
            <h2>REVOCABLE TICKET LICENSE:</h2>
            <h3>HOLDER’S CONDUCT:</h3>
            <p>
              The revokable license granted by any Ticket will be terminated,
              and ejection, detention or arrest may result for any Releasor
              violating any law or any Operator or Venue policy, including
              entering or throwing anything onto the racing surface, offensive
              language and disruptive behavior. Releasors consent to health and
              security searches and/or screening and waive all related claims,
              and also consent to health-related conditions for attendance,
              including any requirements pertaining to the wearing of masks
              and/or social distancing.
            </p>
            <p>
              Upon your entry into an event, you and your belongings may be
              subject to search. You hereby consent to such searches and waive
              any claims that may arise from such searches. If you refuse to
              undergo such searches, you may be denied entry to the event
              without refund or other compensation. Some venues may prohibit
              certain items from being brought onto the premises, including but
              not limited to, alcohol, drugs, cameras, recording devices, laser
              pointers, coolers, bags, chairs, umbrellas, and containers.
            </p>
            <p>
              You agree not to sell, reproduce for sale, alter, or counterfeit
              the ticket for any reason. The resale of any Ticket in violation
              of applicable law, regulation or the policies of the Operator or
              Venue, and/or their designated agents will invalidate the license
              granted by such Ticket and Releasors assume all risks associated
              with the purchase of any Ticket from unauthorized sources. Tickets
              unlawfully resold or attempted to be resold, counterfeit or copied
              tickets may be canceled without compensation. The unique QR code
              on all mobile tickets will allow ONLY ONE ticket, the first
              ticket, to enter the event.
            </p>
            <p>
              Releasors shall at all times abide by any and all rules,
              regulations, restrictions, guidelines and/or policies of any of
              the Released Parties while at the Venue, including any area of the
              Venue requiring special authorization, credentials, or permission
              to enter or any area to which admission by the general public is
              restricted or prohibited (the “Restricted Areas”). The Releasors
              further hereby agree to reimburse the Released Parties for any and
              all reasonable expenses incurred by the Released Parties as a
              result of any damage caused by Releasors while at the Venue and/or
              in Restricted Areas.
            </p>
            <p>
              Please help the Promoter and Venue create a positive experience
              by:
            </p>
            <ul>
              <li>Respecting each other and all staff members atVenue</li>
              <li>
                Refraining from:
                <ul>
                  <li>
                    Sitting in a reserved seat without the appropriate ticket
                  </li>
                  <li>
                    Obscene behavior – fighting, throwing objects, verbally
                    abuse, threatening or illegal behavior
                  </li>
                  <li>Possession of prohibited items inside the venue</li>
                  <li>
                    Intoxication to the point of impairment or resulting in
                    irresponsible behavior
                  </li>
                  <li>
                    Displaying items that impede sightlines, the event broadcast
                    or on-track competition or contain inappropriate
                    controversial, obscene, offensive symbols, slogans, language
                    or imagery
                  </li>
                  <li>Attempting to interfere with on-track competition</li>
                </ul>
              </li>
              <li>Smoking in designated areas ONLY</li>
              <li>
                Abiding by alcohol policies / consume in the designated areas
                when applicable
              </li>
              <li>
                Respecting and Obeying rules in Family Sections when applicable.
              </li>
              <li>
                Following all instructions of Promoter or Venue employees and
                complying with published policies
              </li>
              <li>
                Complying with all local, state and federal laws while on the
                Venue property
              </li>
            </ul>
            <h3>REJECTION AND EJECTION:</h3>
            <p>
              Those found to be in violation of this Code of Conduct may be
              subject to ejection without refund. Violations may result in the
              loss of ticket renewal privileges to future events and/or arrest.
            </p>
            <h3>MINOR INVITEES:</h3>
            <p>
              Should Holder bring Minor(s) to the Event Activities (and such
              Minor(s) is permitted to enter the Event Activities), Holder
              hereby acknowledges and agrees that the Released Parties shall not
              be responsible for the care and safety of Minor(s) at the Event.
              Holder assumes the responsibility of watching and caring for the
              safety of such Minor(s) and guarding such Minor(s) against hazards
              and risks at all times while attending the Event Activities.
            </p>
            <p>
              If Holder does not wish to or is not authorized to grant such
              rights, releases and waivers on behalf of a Minor(s) as contained
              in Section 3 of theAgreement, Holder should immediately leave the
              Venue with the Minor(s).
            </p>
            <p>
              Tickets shall not be used for advertising, promotion (including
              contests), or for other trade purposes. Each Ticket is only good
              for the particular Event indicated and no part of the purchase
              price will be refunded by the non-use of the Ticket. All Event
              Activities are subject to modification and cancellation. The
              Released Parties are not responsible for lost, stolen or
              duplicated Tickets.
            </p>
            <h2>ADVERTISING RELEASE and BROADCAST RIGHTS</h2>
            <h3>ADVERTISING AND PROMOTION RELEASE:</h3>
            <p>
              The Released Parties, their duly authorized agents and assigns,
              may use, on a non-exclusive basis, unless otherwise provided in a
              separate written agreement, Releasors’ name and likeness,
              including photographs, audio/visual content, images and sounds of
              Releasors, in any medium (including print, broadcasts by and
              through television, cable television, radio, pay-per-view, closed
              circuit television, satellite signal, digital signal, film
              productions, audiotape productions, transmissions over the
              Internet, public and private online services authorized by the
              applicable Released Parties, and sales and other commercial
              projects, and the like) for promoting, advertising, or marketing
              the Venue, the Event Activities, the Released Parties, or related
              advertising, promotion, telecast or programming, and Releasors do
              hereby relinquish to the Released Parties in perpetuity all rights
              thereto for such purpose.
            </p>
            <h3>BROADCAST AND OTHER RIGHTS:</h3>
            <p>
              Releasors acknowledge that the Released Parties, as applicable,
              exclusively and in perpetuity own any and all rights to broadcast,
              transmit, film, tape, capture, overhear, photograph, collect or
              record by any means, process, medium or device (including to
              television, cable television, radio, pay-per-view, closed circuit
              television, satellite signal, digital signal, film productions,
              audiotape productions, transmissions over the Internet, public and
              private online services authorized by the applicable Released
              Parties, sales and other commercial projects, and the like),
              whether or not currently in existence, all images, sounds and data
              arising from or during the Event Activities and that the Released
              Parties, as applicable, are and shall be the owners of any and all
              intellectual property rights (including, but not limited to,
              patents, copyrights, trademarks, design rights, and other
              proprietary rights) worldwide in and to these works and in and to
              any other works, copyrightable or otherwise created from the
              images, sounds and data arising from, during or in connection with
              the Event Activities. In addition to the extent not already owned
              by the applicable Released Parties, Releasors hereby assign to the
              Released Parties exclusively and in perpetuity any and all rights
              set forth above. Releasors represent and warrant that as of the
              Releasors’ acceptance of these Terms, Releasors have not granted
              to any third party the rights described herein. Releasors agree to
              take all steps reasonably necessary, and all steps requested by
              the Released Parties, to protect, perfect or effectuate the
              Released Parties’ ownership or other interest in these rights.
              Releasors agree not to take any action, nor cause others to take
              any action, nor enter into any third-party agreement which would
              contravene, diminish, encroach or infringe upon these rights of
              the Released Parties. Releasors shall not transmit or aid in
              transmitting any images, videos, audio, livestreams or other
              accounts or descriptions about the Event Activities in any media.
              Releasors consent to and waive compensation for use of their
              images or other personality rights in connection with the Event
              Activities.
            </p>
            <p>Updated 03.15.22</p>
          </Container>
        </Styles>
      </Layout>
    )
  }
}
